import React from "react";
import styles from "./AnimatedCard.module.scss";
import { Card } from "@mui/material";

interface AnimatedCardProps {
  rotation?: number;
  rotationHover?: number;
  afterRotation?: number;
  children?: React.ReactNode;
  hasMaxW?: boolean;
}

export const AnimatedCard: React.FC<AnimatedCardProps> = ({
  rotation = 0,
  rotationHover = 12,
  afterRotation = 25,
  children,
  hasMaxW = true,
}) => {
  const getRotationClass = (angle: number) =>
    angle >= 0 ? `rotate-${angle}` : `-rotate-${Math.abs(angle)}`;

  const rotationClass = getRotationClass(rotation);
  const hoverClass = `hover:rotate-${rotationHover}`;
  const afterRotationClass = getRotationClass(afterRotation);

  return (
    <div
      className={`${styles[rotationClass]}  ${hasMaxW ? styles.card : styles.cardNoMax} ${styles[hoverClass]}`}
    >
      <div className={`${styles.before} ${styles[afterRotationClass]}`} />
      <Card>{children}</Card>
    </div>
  );
};
